import api from '@/api';
import { TotemCartContext } from '@/contexts/TotemCartContext';
import { useTranslate } from '@/hooks/useTranslate';
import { Box, Flex, IconButton, SimpleGrid, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { IoRocketSharp } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { GoalChart } from './GoalChart';

interface GoalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const Goals: React.FC<GoalProps> = ({ isOpen, onClose, onOpen }) => {
  const { t } = useTranslate();
  const [dataState, setDataState] = useState<any>();
  const { data } = useQuery('api.home.goal', api.home.goal, {
    initialData: {} as any,
    onSuccess: (result) => {
      setDataState(result);
    },
  });
  const { isOpen: isOpenCart } = useContext(TotemCartContext);
  const isValue =
    dataState?.day &&
    Object.prototype.hasOwnProperty?.call(dataState.day, 'goalValue') &&
    Object.prototype.hasOwnProperty?.call(dataState.day, 'countValue');

  const goals = [
    {
      id: 0,
      name: t('clients'),
      color: '#008ffb',
      key: isValue ? 'countClient' : 'clients',
      keyCount: 'clients',
      keyGoal: 'goalClient',
    },
    {
      id: 1,
      name: t('products.sold'),
      color: '#1b998b',
      key: isValue ? 'countProducts' : 'products',
      keyCount: 'products',
      keyGoal: 'goalProducts',
    },
    {
      id: 2,
      name: 'Produtos por Venda',
      color: '#1b998b',
      key: isValue ? 'countProductsPerSale' : 'productsPerSale',
      keyCount: 'products',
      keyGoal: 'goalProductsPerSale',
    },
    {
      id: 3,
      name: t('averageTicket'),
      color: '#ff4560',
      key: isValue ? 'countTicket' : 'ticket',
      keyCount: 'ticket',
      keyGoal: 'goalTicket',
      isPrice: true,
    },
    {
      id: 4,
      name: t('billing'),
      color: '#feb019',
      key: isValue ? 'countValue' : 'value',
      keyCount: 'value',
      keyGoal: 'goalValue',
      isPrice: true,
    },
  ];

  return !isOpenCart ? (
    <>
      <Box
        width="700px"
        bg="white"
        position="fixed"
        bottom={3}
        right={3}
        zIndex={1000}
        shadow="lg"
        hidden={!isOpen}
        rounded="lg"
      >
        <Flex
          py={2}
          px={4}
          align="center"
          justify="space-between"
          onClick={onClose}
          userSelect="none"
          borderBottomWidth="1px"
          borderColor="blackAlpha.100"
        >
          <Text fontWeight="bold">{t('goals.daily')}</Text>
          <Flex align="center" cursor="pointer" gridGap={2}>
            <Text fontSize="sm">{t('goals.close')}</Text>
            <FiChevronDown />
          </Flex>
        </Flex>
        <SimpleGrid columns={5} p={4} spacing={4}>
          {goals.map((goal) => {
            return (
              <GoalChart
                key={goal.id}
                result={data}
                goal={{
                  ...goal,
                  value: (data?.day as any)?.[goal?.key] ?? 0,
                  current: (data?.day as any)?.[goal?.keyCount] ?? 0,
                  reach: (data?.day as any)?.[goal?.keyGoal] ?? 0,
                }}
              />
            );
          })}
        </SimpleGrid>
      </Box>

      <Flex position="fixed" align="center" zIndex={999} right={3} bottom={3} hidden={isOpen}>
        <Box
          bg="white"
          color="#000"
          border="1px"
          borderColor="blackAlpha.100"
          px={4}
          py={2}
          mr={3}
          rounded="full"
          shadow="lg"
          fontWeight="bold"
          onClick={onOpen}
          cursor="pointer"
        >
          {t('goals.daily')}
        </Box>
        <IconButton
          as={motion.button}
          icon={<IoRocketSharp size="30px" />}
          bg="green.400"
          rounded="full"
          color="white"
          aria-label="Goals Button"
          w="80px"
          h="80px"
          shadow="lg"
          _focus={{}}
          initial={{ rotate: -360 }}
          animate={{ rotate: 0 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.9, rotate: 360, transition: { duration: 0.3 } }}
          onClick={onOpen}
        />
      </Flex>
    </>
  ) : (
    <></>
  );
};
