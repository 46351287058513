import { useStatusPage } from '@/hooks/useStatusPage';
import { Box, Flex } from '@chakra-ui/react';
import Footer from './Footer';

import Header from './Header';

const Container: React.FC = ({ children }) => {
  const { StatusPageHeader } = useStatusPage();

  return (
    <Flex minH="100vh" w="100%" maxW={2000} direction="column">
      <StatusPageHeader />
      <Header />
      <Box flexGrow={1} px={3}>
        {children}
      </Box>
      <Footer />
    </Flex>
  );
};

export default Container;
