import { useTranslate } from '@/hooks/useTranslate';
import { Flex, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

const LiveButton: React.FC<any> = () => {
  const { t } = useTranslate();
  const router = useRouter();

  return (
    <Flex
      position="fixed"
      align="center"
      zIndex="999"
      right="3"
      bottom={router.asPath === '/video' ? '80px' : '190px'}
      pt={4}
    >
      <Box
        bg="white"
        color="#000"
        border="1px"
        borderColor="blackAlpha.100"
        px={4}
        py={2}
        mr={3}
        rounded="full"
        shadow="lg"
        fontWeight="bold"
        d="flex"
        onClick={() => router.push('/video')}
        cursor="pointer"
      >
        {t('video')}
      </Box>
      <motion.div
        initial={{ rotate: -360 }}
        animate={{ rotate: 0 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.9, rotate: 360, transition: { duration: 0.3 } }}
      >
        <Box
          bgColor="red"
          rounded="50"
          d="flex"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          shadow="xl"
          flexDir={'column'}
          _hover={{
            cursor: 'pointer',
            bgColor: 'red.500',
          }}
          ml="auto"
          onClick={() => router.push('/video')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="6"
            viewBox="0 0 16 16"
            width="16"
            focusable="false"
            style={{
              pointerEvents: 'none',
              display: 'block',
              width: '70%',
              height: '70%',
            }}
          >
            <path
              // eslint-disable-next-line max-len
              d="M9 8c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1Zm1.11 2.13.71.71C11.55 10.11 12 9.11 12 8c0-1.11-.45-2.11-1.18-2.84l-.71.71c.55.55.89 1.3.89 2.13 0 .83-.34 1.58-.89 2.13Zm-4.93.71.71-.71C5.34 9.58 5 8.83 5 8c0-.83.34-1.58.89-2.13l-.71-.71C4.45 5.89 4 6.89 4 8c0 1.11.45 2.11 1.18 2.84Zm7.05 1.41.71.71C14.21 11.69 15 9.94 15 8s-.79-3.69-2.06-4.96l-.71.71C13.32 4.84 14 6.34 14 8c0 1.66-.68 3.16-1.77 4.25Zm-9.17.71.71-.71C2.68 11.16 2 9.66 2 8c0-1.66.68-3.16 1.77-4.25l-.71-.71C1.79 4.31 1 6.06 1 8s.79 3.69 2.06 4.96Z"
              fill="white"
            ></path>
          </svg>
        </Box>
      </motion.div>
    </Flex>
  );
};

export default LiveButton;
