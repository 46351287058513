import { Menu, MenuButton, MenuList, MenuItem, Text, Flex, Link, Button } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { RoutesProps } from '../containers/Header';

interface HeaderMenuProps {
  label: string;
  routes: RoutesProps['routes'];
}

interface MenuIconProps {
  routes: HeaderMenuProps['routes'];
  isOpen: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({ routes, isOpen }) => {
  if (routes.length > 1) {
    if (isOpen) {
      return <FiChevronUp size="1.5rem" />;
    } else {
      return <FiChevronDown size="1.5rem" />;
    }
  }
  return <></>;
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ label, routes }) => {
  const { push, pathname } = useRouter();
  const menuRoutes = routes.map((route) => route.route);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            minH={50}
            bgColor={menuRoutes.includes(pathname) ? 'gray.300' : 'gray.100'}
            _focus={{ outline: 'none' }}
            _active={{ bg: 'gray.300' }}
            w="100%"
            rounded="lg"
            onClick={() => {
              if (routes.length == 1) {
                routes[0].route ? push(routes[0].route) : routes[0].onClick?.();
              }
            }}
          >
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Text textAlign="center" w="100%" fontSize="xl">
                {label}
              </Text>
              <MenuIcon isOpen={isOpen} routes={routes} />
            </Flex>
          </MenuButton>
          {routes.length > 1 && (
            <MenuList mt={-1} zIndex="100" py={0} overflow="hidden">
              {routes
                .filter((route) => route.show)
                .map((item) => (
                  <Link
                    key={item.id}
                    href={item?.link?.href}
                    isExternal={!!item?.link}
                    _hover={{ textDecor: 'none' }}
                  >
                    <MenuItem
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        } else if (item.route) {
                          push(item.route);
                          return;
                        }
                      }}
                      // color={pathname === item.route ? '#FFF' : '#000'}
                      fontSize="1.2rem"
                      justifyContent="space-between"
                      alignContent="center"
                    >
                      {item.label}
                    </MenuItem>
                  </Link>
                ))}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};

export default HeaderMenu;
