import api from '@/api';
import { useVariableValue } from '@devcycle/devcycle-react-sdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { createContext } from 'react';
import { useQuery } from 'react-query';
import { GoalContext } from './GoalContext';
import { TotemCartContext } from './TotemCartContext';
import { useRouter } from 'next/router';
interface iFoodProps {
  enableIfood: boolean;
}

const IFoodContext = createContext<iFoodProps>({} as iFoodProps);

const IFoodProvider: React.FC = ({ children }) => {
  const [isIfoodEnabled, setIsIfoodEnabled] = useState(false);
  const isWidgetInitialized = useRef(false);
  const isWidgetEnabled = useVariableValue('ifood-widget', false);
  const router = useRouter();
  const { data } = useQuery('api.company.get', api.company.get, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
    onSuccess: (result) => {
      setIsIfoodEnabled(result.merchantEnabled);
    },
    enabled: isWidgetEnabled,
  });

  useEffect(() => {
    if (router.pathname === `/pedidos`) {
      window.iFoodWidget?.show();
    } else {
      window.iFoodWidget?.hide();
    }
  }, [router.pathname]);

  const { isOpen: isOpenCart } = useContext(TotemCartContext);
  const { isOpen: isOpenGoal } = useContext(GoalContext);
  useEffect(() => {
    try {
      if (!isIfoodEnabled && !isWidgetInitialized.current) {
        return;
      }
      if (isOpenCart || isOpenGoal) {
        window.iFoodWidget?.hide();
      } else {
        window.iFoodWidget?.show();
      }
    } catch (err) {
      console.log(err);
      //
    }
  }, [isIfoodEnabled, isOpenCart, isOpenGoal]);

  useEffect(() => {
    if (!isWidgetEnabled) {
      window.iFoodWidget?.hide();
    }
  }, [isWidgetEnabled]);

  useEffect(() => {
    if (!isWidgetEnabled) {
      return;
    }

    if (isIfoodEnabled && !isWidgetInitialized.current && data?.merchant && data?.merchantEnabled) {
      window.iFoodWidget?.init({
        merchantIds: [data?.merchant],
        widgetId: '25152778-721b-4395-beaf-3c3f26894f85',
      });
      isWidgetInitialized.current = true;
    }
  }, [data?.merchant, data?.merchantEnabled, isIfoodEnabled, isWidgetEnabled]);

  return (
    <IFoodContext.Provider value={{ enableIfood: isIfoodEnabled }}>
      {children}
    </IFoodContext.Provider>
  );
};

export { IFoodProvider, IFoodContext };
