import { ChakraProvider, ThemeConfig, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
});

const Global = {
  global: {
    body: {
      // background: '#FFFFFF',
    },
  },
};

const config: ThemeConfig = {
  initialColorMode: 'light',
};

const theme = extendTheme({
  styles: Global,
  config,
  colors: {
    // white: '#FFF',
    primary: '#ffa400',
    // secondary: '#ced2d8',
    // success: '#2eb85c',
    // info: '#39f',
    // warning: '#f9b115',
    // danger: '#e55353',
    // primaryHover: '#d98b00',
    // light: '#fefefe',
    // dark: '#000',
    // gray: '#F2F2F2',
    // grayDark: '#515555',
    primaryLight: '#FFB633',
    pro: '#00ffcc',
  },
  fonts: {
    body: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
  },
  breakpoints,
});

export const ThemeProvider = ({ children }: { children: any }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);
