import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ls } from '../lib/local-storage';

const STORAGE_KEY = '@mais1kds/alert';
export interface AlertProps {
  type: 'MINIMUM_STOCK' | 'PROFRANCHISING' | 'FURNACE_RESET';
  message: string;
}

type UseAlertDataStore = {
  alert?: AlertProps;
  setAlert: (alert: AlertProps) => void;
};

export const useAlertData = create<UseAlertDataStore>()(
  persist(
    (set) => {
      return {
        alert: undefined,
        setAlert: (alert) => set({ alert }),
      };
    },
    {
      name: STORAGE_KEY,
      storage: createJSONStorage(() => ls),
    },
  ),
);
