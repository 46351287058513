import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { createContext } from 'react';
import { SocketCartModel } from './SocketContext';
import CartButton from '@/views/orders/components/CartButton';
import { useRouter } from 'next/router';
import LiveButton from '@/views/live/LiveButton';
import { useQuery } from 'react-query';
import api from '@/api';

interface TotemCartContext {
  receiveUpdate: (data: SocketCartModel) => void;
  updateSeen: (totemIndex: number, seen: boolean) => void;
  updateSelected: (totemIndex: number) => void;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  totens: TotemProps[];
}

type TotemProps = SocketCartModel & { seen: boolean; selected: boolean };

const TotemCartContext = createContext<TotemCartContext>({} as TotemCartContext);

const TotemCartProvider: React.FC = ({ children }) => {
  const [totens, setTotens] = useState<TotemProps[]>([]);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const router = useRouter();

  const pathNames = ['/pedidos', '/historico-de-pedidos', '/chamados'];
  const showButton = pathNames.includes(router?.pathname);

  const { data: liveResult } = useQuery('api.live.get', api.live.get, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
  });

  const updateSelected = (totemIndex: number) => {
    setTotens((prev) => {
      if (prev.length) {
        const newData = prev.map((totem, index) => ({
          ...totem,
          selected: index === totemIndex,
        }));
        return newData;
      }
      return prev;
    });
  };

  const updateSeen = useCallback((totemIndex: number, seen: boolean) => {
    setTotens((prev) => {
      if (totemIndex > -1) {
        const newData = [...prev];
        newData[totemIndex] = { ...newData[totemIndex], seen };
        return newData;
      }
      return prev;
    });
  }, []);

  const receiveUpdate = useCallback(
    (data: SocketCartModel) => {
      setTotens((prev) => {
        const totemIndex = prev.findIndex((el) => el.totem.uuid === data.totem.uuid);
        if (totemIndex === -1) {
          return [...prev, { ...data, seen: prev.length === 0, selected: prev.length === 0 }];
        } else {
          const newData = [...prev];
          const curData = newData[totemIndex];
          newData[totemIndex] = { ...data, seen: curData.selected, selected: curData.selected };
          return newData;
        }
      });
    },
    [setTotens],
  );

  return (
    <TotemCartContext.Provider
      value={{ receiveUpdate, updateSeen, updateSelected, isOpen, onClose, onOpen, totens }}
    >
      {showButton && <CartButton isOpen={isOpen} onOpen={onOpen} />}
      {liveResult && <LiveButton />}
      {children}
    </TotemCartContext.Provider>
  );
};

export type { TotemProps };
export { TotemCartProvider, TotemCartContext };
