import { useTranslate } from '@/hooks/useTranslate';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

interface ModalStoreClosedProps {
  onClose: () => void;
  openStore?: () => void;
}

const ModalStoreClosed: React.FC<ModalStoreClosedProps> = ({ openStore, onClose }) => {
  const { t } = useTranslate();

  const open = () => {
    openStore?.();
    onClose();
  };
  return (
    <Box>
      <Text>{t('newOrder.closed')}</Text>
      <Box my={2} fontWeight={500}>
        {t('openStore.confirm')}
      </Box>
      <Flex>
        <Button onClick={() => onClose()} variant={'outline'} colorScheme="blackAlpha" mr={5}>
          {t('cancel')}
        </Button>
        <Button onClick={() => open()} colorScheme={'green'}>
          {t('openStore')}
        </Button>
      </Flex>
    </Box>
  );
};

export default ModalStoreClosed;
