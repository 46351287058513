import { currency } from '@/helpers/currency';
import { Flex, Text } from '@chakra-ui/react';
import { Cell, Pie, PieChart } from 'recharts';

interface GoalChartProps {
  goal: {
    name: string;
    value: number;
    current: number;
    reach: number;
    color: string;
    isPrice?: boolean;
  };
  result: any;
}

export const GoalChart: React.FC<GoalChartProps> = ({ goal, result }) => {
  const isValue =
    result?.day &&
    Object.prototype.hasOwnProperty?.call(result.day, 'goalValue') &&
    Object.prototype.hasOwnProperty?.call(result.day, 'countValue');

  const data = [
    {
      name: 'Restante',
      value: 100 - goal.current,
    },
    { name: goal.name, value: goal.current },
  ];

  return (
    <Flex direction="column" align="center">
      <PieChart width={150} height={150}>
        <Pie
          dataKey="value"
          data={data}
          innerRadius={45}
          outerRadius={60}
          startAngle={-50}
          endAngle={230}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? 'rgba(0, 0, 0, .1)' : goal.color} />
          ))}
        </Pie>
        <text x={75} y={140} textAnchor="middle" style={{ fontSize: '0.9rem' }}>
          {isValue ? (goal.isPrice ? currency(goal.value) : goal.value) : `${goal.value}%`}
        </text>
      </PieChart>
      <Text textAlign="center">{goal.name}</Text>
    </Flex>
  );
};
