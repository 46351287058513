import { useContext } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Link,
} from '@chakra-ui/react';

import { StatusPageContext } from '../contexts/StatusPageContext';
import { useTranslate } from './useTranslate';

export const useStatusPage = () => {
  const { data } = useContext(StatusPageContext);
  const { t } = useTranslate();
  const StatusPageHeader = () => {
    if (data?.page?.status === 'UP' || !data) {
      return <></>;
    }

    return (
      <>
        {data?.page?.status === 'UNDERMAINTENCE' ? (
          <Box width="100%" position="relative">
            <Alert status="info" justifyContent={{ base: 'unset', md: 'space-between' }}>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                width="100%"
                justifyContent="space-between"
              >
                <Flex flexDir={{ base: 'column', md: 'row' }}>
                  <AlertIcon />
                  <AlertTitle textAlign="center" fontSize="sm">
                    {t('statuspage.maintence')}
                  </AlertTitle>
                  <AlertDescription fontSize="sm" textAlign={{ base: 'center', md: 'left' }} mb={1}>
                    {data?.activeMaintenances &&
                      data?.activeMaintenances?.length > 0 &&
                      data?.activeMaintenances[0].name}
                  </AlertDescription>
                </Flex>
                <Button
                  size="xs"
                  colorScheme="blackAlpha"
                  _dark={{
                    bg: 'red.200',
                  }}
                >
                  {t('statuspage.viewUpdates')}
                </Button>
              </Flex>
            </Alert>
          </Box>
        ) : data?.activeIncidents?.length > 0 ? (
          <Box width="100%" position="relative">
            <Alert status="error" justifyContent={{ base: 'unset', md: 'space-between' }}>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                width="100%"
                justifyContent="space-between"
              >
                <Flex flexDir={{ base: 'column', md: 'row' }}>
                  <AlertIcon />
                  <AlertTitle textAlign="center" fontSize="sm">
                    {t('statuspage.incidents')} ({data?.activeIncidents?.length}):
                  </AlertTitle>
                  <AlertDescription fontSize="sm" textAlign={{ base: 'center', md: 'left' }} mb={1}>
                    {data?.activeIncidents?.map((el, index) => {
                      if (data?.activeIncidents?.length === 1) {
                        return (
                          <Link target="_blank" href={el.url} key={el.id}>
                            {el.name}
                          </Link>
                        );
                      }
                      return index + 1 === data?.activeIncidents?.length ? (
                        <Link target="_blank" href={el.url} key={el.id}>
                          {el.name}
                        </Link>
                      ) : (
                        <>
                          <Link target="_blank" href={el.url} key={el.id}>
                            {el.name}
                          </Link>{' '}
                          •{' '}
                        </>
                      );
                    })}
                  </AlertDescription>
                </Flex>
                <Link
                  href="https://status.mais1cafe.com.br"
                  target="_blank"
                  alignSelf="flex-end"
                  _hover={{ textDecoration: 'none' }}
                >
                  <Button
                    size="xs"
                    colorScheme="blackAlpha"
                    _dark={{
                      bg: 'red.200',
                    }}
                  >
                    {t('statuspage.viewUpdates')}
                  </Button>
                </Link>
              </Flex>
            </Alert>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  return { StatusPageHeader, data };
};
