import { createContext, useState } from 'react';

import api from '@/api';
import { CashRegisterModel } from '@/api/controllers/cashregister.controller';
import { useQuery, useQueryClient } from 'react-query';
import { useVariableValue } from '@devcycle/devcycle-react-sdk';

interface CashRegisterContextProps {
  isOpen: boolean;
  openCashRegister: () => void;
}

interface CashRegisterProps {
  isOpen: boolean;
}

export const CashRegisterContext = createContext({} as CashRegisterContextProps);

export const CashRegisterProvider: React.FC = ({ children }) => {
  const [cashRegister, setCashRegister] = useState<CashRegisterProps>({ isOpen: false });
  const queryClient = useQueryClient();
  const featureFlagCashRegister = useVariableValue('cash-register', false);
  const { isOpen } = cashRegister;

  useQuery('api.cashRegister.findLastCashRegister', api.cashRegister.findLastCashRegister, {
    refetchOnWindowFocus: false,
    onSuccess: (data: CashRegisterModel) => {
      setCashRegister((prev) => ({ ...prev, isOpen: data?.action !== 'CLOSE' }));
    },
    enabled: featureFlagCashRegister,
  });

  const openCashRegister = async () => {
    await queryClient.invalidateQueries('api.cashRegister.findLastCashRegister');
    await queryClient.invalidateQueries('api.cashRegister.listDailyCashRegister');
  };

  return (
    <CashRegisterContext.Provider value={{ isOpen, openCashRegister }}>
      {children}
    </CashRegisterContext.Provider>
  );
};
