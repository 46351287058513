import { useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { createContext } from 'react';
import { Goals } from '../components/goals/Goals';

interface GoalProps {
  isOpen: boolean;
}

const GoalContext = createContext<GoalProps>({} as GoalProps);

const GoalProvider: React.FC = ({ children }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const router = useRouter();
  const pathNames = ['/pedidos', '/historico-de-pedidos'];
  const showButton = pathNames.includes(router.pathname);

  return (
    <GoalContext.Provider value={{ isOpen }}>
      {showButton && <Goals isOpen={isOpen} onClose={onClose} onOpen={onOpen} />}
      {children}
    </GoalContext.Provider>
  );
};

export { GoalContext, GoalProvider };
