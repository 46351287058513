import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { usePin } from '@/hooks/usePin';
import Loading from '@/containers/Loading';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslate } from '../hooks/useTranslate';
import { useCompany } from '../hooks/useCompany';
import { useIsDVCInitialized, useDVCClient } from '@devcycle/devcycle-react-sdk';
import { isServer } from '@/lib/isServer';
import http from '../api/http';

interface PinProps {
  apiKey: string;
}

const AuthContext = createContext({} as PinProps);

const AuthProvider: React.FC = ({ children }) => {
  const [start, setStart] = useState(false);
  const { apiKey, uid, lang } = usePin();
  const { company, jwt, setJwt } = useCompany();
  const { i18n } = useTranslate();
  const router = useRouter();

  const isDvcEnabled = useIsDVCInitialized();
  const devCycleClient = useDVCClient();

  useEffect(() => {
    if (isDvcEnabled && apiKey && company && !isServer) {
      devCycleClient.identifyUser({
        user_id: apiKey,
        isAnonymous: false,
        name: `kds-${company}`,
        customData: {
          companyId: company,
        },
      });
    }
  }, [isDvcEnabled, apiKey, company, devCycleClient]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    if (!apiKey || !uid) {
      signOut(getAuth());
      router.push('/login');
    } else {
      setStart(true);
    }

    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    const fetchJwt = async () => {
      if (!jwt) {
        const { data, status } = await http.get('/v3/kds/token');

        if (status === 200) {
          setJwt(data);
        }
      }
    };

    fetchJwt().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof window !== undefined && !start) {
    return <Loading />;
  }

  return <AuthContext.Provider value={{ apiKey }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
