import { useTranslate } from '@/hooks/useTranslate';
import { Flex, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { GrCart } from 'react-icons/gr';

interface CartButtonProps {
  isOpen: boolean;
  onOpen: () => void;
}

const CartButton: React.FC<CartButtonProps> = ({ isOpen, onOpen }) => {
  const { t } = useTranslate();

  return (
    <Flex
      position="fixed"
      align="center"
      zIndex="999"
      right={isOpen ? '340px' : '3'}
      bottom="100px"
      pt={4}
    >
      <Box
        bg="white"
        color="#000"
        border="1px"
        borderColor="blackAlpha.100"
        px={4}
        py={2}
        mr={3}
        rounded="full"
        shadow="lg"
        fontWeight="bold"
        d={isOpen ? 'none' : 'flex'}
        onClick={onOpen}
        cursor="pointer"
      >
        {t('cart')}
      </Box>
      <motion.div
        initial={{ rotate: -360 }}
        animate={{ rotate: 0 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.9, rotate: 360, transition: { duration: 0.3 } }}
      >
        <Box
          bgColor="#FFA400"
          rounded="50"
          d={isOpen ? 'none' : 'flex'}
          width={isOpen ? '0' : '80px'}
          height={isOpen ? '0' : '80px'}
          justifyContent="center"
          alignItems="center"
          shadow="xl"
          flexDir={isOpen ? 'row' : 'column'}
          _hover={{
            cursor: 'pointer',
            bgColor: '#FFA400',
          }}
          ml="auto"
          onClick={onOpen}
        >
          <GrCart color="#000" size="30px" />
        </Box>
      </motion.div>
    </Flex>
  );
};

export default CartButton;
