import { createContext, useCallback, useEffect, useState } from 'react';

import { usePin } from '@/hooks/usePin';
import { MessageModel, ServiceModel, ServiceStatus } from '@/views/chats/components/types';
import {
  collection,
  getFirestore,
  query,
  where,
  orderBy,
  doc,
  updateDoc,
  onSnapshot,
  Unsubscribe,
  increment,
  arrayUnion,
  getDoc,
  getDocs,
  limit,
  startAfter,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import toast from '@/helpers/toast';
import { useTranslate } from '@/hooks/useTranslate';
import { generateRandomUID } from '@/helpers/generateRandomUID';
import firestoreSize from 'firestore-size';

const MESSAGES_LIMIT = 20;
const CLOSED_SERVICES_LIMIT = 10;
const MAX_DOC_SIZE = 1000000;

interface ServicesStateProps {
  open: ServiceModel[];
  closed: ServiceModel[];
}

interface FirebaseContextProps {
  listServices: (status: ServiceStatus) => void;
  getService: (serviceId: string) => void;
  setCurrentService: React.Dispatch<React.SetStateAction<ServiceModel | undefined>>;
  sendMessage: (text: string, userUid: string, serviceId: string) => void;
  closeService: (serviceId: string) => void;
  loadNextServicesPage: () => void;
  services: ServicesStateProps;
  currentService: ServiceModel | undefined;
  loadingServices: boolean;
  loadingServiceClose: boolean;
  loadingNextServicePage: boolean;
  newMessagesChatCounter: number;
}

const FirebaseContext = createContext<FirebaseContextProps>({} as FirebaseContextProps);

const FirebaseProvider: React.FC = ({ children }) => {
  const [services, setServices] = useState<ServicesStateProps>({ open: [], closed: [] });
  const [currentService, setCurrentService] = useState<ServiceModel>();
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingServiceClose, setLoadingServiceClose] = useState(false);
  const [serviceListener, setServiceListener] = useState<Unsubscribe>();
  const [newMessagesChatCounter, setNewMessagesChatCounter] = useState(0);
  const [loadingNextServicePage, setLoadingNextServicePage] = useState(false);
  const [oldestService, setOldestService] = useState<ServiceModel>();
  const [servicesListener, setServicesListener] = useState<{
    open?: Unsubscribe;
    closed?: Unsubscribe;
  }>({ open: undefined, closed: undefined });
  const { pathname } = useRouter();

  const { uid: storeUID } = usePin();
  const auth = getAuth();
  const { t } = useTranslate();

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }
    const oldestListener = listenToOldestService();
    // Clear all listener if they do exist
    return () => {
      if (servicesListener.open) {
        servicesListener.open();
      }
      if (servicesListener.closed) {
        servicesListener.closed();
      }
      if (serviceListener) {
        serviceListener();
      }
      if (oldestListener) {
        oldestListener();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  // Get the first created service (used for closed services pagination)
  const listenToOldestService = useCallback(() => {
    try {
      const serviceQuery = query(
        collection(getFirestore(), 'chats'),
        where('store.uid', '==', storeUID),
        where('status', '==', 'closed'),
        orderBy('notRead.store', 'asc'),
        orderBy('lastMessage.createdAt', 'asc'),
        limit(1),
      );

      const listener = onSnapshot(serviceQuery, (snapshot) => {
        if (snapshot.docs.length) {
          setOldestService({ ...snapshot.docs[0].data(), id: snapshot.docs[0].id } as ServiceModel);
        }
      });

      return listener;
    } catch (err) {
      toast.error(t('firestore.listenCall.error'));
    }
  }, [storeUID, t]);

  // Function to update the store read cound of a given service
  const updateServiceReadCount = useCallback(
    async (value: number, serviceId: string) => {
      try {
        await updateDoc(doc(getFirestore(), 'chats', serviceId), {
          'notRead.store': value,
        });
      } catch (err) {
        toast.error(t('firestore.read.error'));
      }
    },
    [t],
  );

  // Clear the not read counter when the chat is focused
  useEffect(() => {
    if (currentService) {
      if (pathname === '/chamados') {
        updateServiceReadCount(0, currentService.id);
      }
    }
  }, [currentService, pathname, updateServiceReadCount]);

  //Get the service messages
  const getService = useCallback(
    async (serviceId: string) => {
      try {
        //unsubscribe to old listener
        if (serviceListener) {
          serviceListener();
        }

        const localServiceListener = onSnapshot(
          doc(getFirestore(), 'chats', serviceId),
          (snapshot) => {
            const snapshotData = snapshot.data() as Omit<ServiceModel, 'id'>;
            if (snapshotData) {
              setCurrentService({
                ...snapshotData,
                id: snapshot.id,
              });
            }
          },
        );
        setServiceListener(() => localServiceListener);
      } catch (err) {
        toast.error(t('firestore.messages.fetchError'));
      }
    },
    [serviceListener, t],
  );

  // List services by status
  const listServices = useCallback(
    async (status: ServiceStatus) => {
      try {
        if (servicesListener[status]) {
          return;
        }

        setLoadingServices(true);
        const servicesQuery = query(
          collection(getFirestore(), 'chats'),
          where('store.uid', '==', storeUID),
          where('status', '==', status),
          orderBy('notRead.store', 'desc'),
          orderBy('lastMessage.createdAt', 'desc'),
          limit(status === 'open' ? Infinity : CLOSED_SERVICES_LIMIT),
        );

        const listener = onSnapshot(servicesQuery, (snapshot) => {
          let newMessagesChat = 0;
          const servicesData = snapshot.docs.map((service) => {
            const serviceData = service.data() as ServiceModel;
            if (serviceData.notRead.store > 0) {
              newMessagesChat += 1;
            }

            return {
              ...serviceData,
              id: service.id,
            };
          }) as ServiceModel[];

          if (status === 'open') {
            setNewMessagesChatCounter(newMessagesChat);
          }
          setServices((prev) => ({
            ...prev,
            [status]: servicesData,
          }));
          setLoadingServices(false);
        });
        setServicesListener((prev) => ({ ...prev, [status]: listener }));
      } catch (err) {
        toast.error(
          status === 'closed' ? t('firestore.call.errorClosed') : t('firestore.call.errorOpen'),
        );
        setLoadingServices(false);
      }
    },
    [setLoadingServices, setServicesListener, t, servicesListener, storeUID],
  );

  // Load next closed services page
  const loadNextServicesPage = async () => {
    try {
      const lastLoadedService = services.closed[services.closed.length - 1];
      if (
        loadingNextServicePage ||
        (oldestService && oldestService?.createdAt == lastLoadedService.createdAt)
      ) {
        return;
      }

      setLoadingNextServicePage(true);
      const lastServiceRef = doc(getFirestore(), `chats/${lastLoadedService.id}`);
      const lastServiceObj = await getDoc(lastServiceRef);

      const servicesQuery = query(
        collection(getFirestore(), 'chats'),
        where('store.uid', '==', storeUID),
        where('status', '==', 'closed'),
        orderBy('notRead.store', 'desc'),
        orderBy('lastMessage.createdAt', 'desc'),
        limit(CLOSED_SERVICES_LIMIT),
        startAfter(lastServiceObj),
      );

      const servicesResult = await getDocs(servicesQuery);
      const newLoadedServices = servicesResult.docs.map((service) => ({
        ...service.data(),
        id: service.id,
      })) as ServiceModel[];

      setServices((prev) => ({ ...prev, closed: [...prev.closed, ...newLoadedServices] }));
      setLoadingNextServicePage(false);
    } catch (err) {
      toast.error(t('firestore.call.nextPageError'));
      setLoadingNextServicePage(false);
    }
  };

  // Send a new message
  const sendMessage = useCallback(
    async (text: string, userUid: string, serviceId: string) => {
      try {
        if (!currentService) {
          return;
        }

        // Construct the new message object
        const newMessage: MessageModel = {
          id: generateRandomUID(20),
          message: text,
          sender: 0,
          receiver: 1,
          users: [storeUID, userUid],
          createdAt: new Date().toISOString(),
        };

        const newDocument: ServiceModel = {
          ...currentService,
          messages: [...currentService?.messages, newMessage],
        };
        const docSize = firestoreSize(newDocument);
        if (docSize >= MAX_DOC_SIZE) {
          const oldMessages = [...currentService.messages];
          await updateDoc(doc(getFirestore(), 'chats', serviceId), {
            lastMessage: newMessage,
            'notRead.customer': increment(1),
            messages: [...oldMessages.slice(50, oldMessages.length), newMessage],
          });
        } else {
          await updateDoc(doc(getFirestore(), 'chats', serviceId), {
            lastMessage: newMessage,
            'notRead.customer': increment(1),
            messages: arrayUnion(newMessage),
          });
        }
      } catch (err) {
        toast.error(t('firestore.message.sendError'));
      }
    },
    [storeUID, currentService, t],
  );

  // Update a service status to closed
  const closeService = useCallback(
    async (serviceId: string) => {
      try {
        setLoadingServiceClose(true);

        // Update the service status in the database
        await updateDoc(doc(getFirestore(), 'chats', serviceId), {
          status: 'closed',
        });

        // Update the local current service status to closed
        setCurrentService((prev) => {
          if (prev?.id === serviceId) {
            return { ...prev, status: 'closed' };
          }
        });
        setLoadingServiceClose(false);
      } catch (err) {
        toast.error(t('firestore.call.closeError'));
        setLoadingServiceClose(false);
      }
    },
    [t],
  );

  useEffect(() => {
    const subscription = onAuthStateChanged(auth, async (user) => {
      if (loadingServices) {
        return;
      }
      if (user) {
        if (!servicesListener['open']) {
          listServices('open');
        }
      }
    });
    return subscription;
  }, [auth, listServices, servicesListener, loadingServices]);

  return (
    <FirebaseContext.Provider
      value={{
        listServices,
        getService,
        setCurrentService,
        sendMessage,
        closeService,
        loadNextServicesPage,
        services,
        currentService,
        loadingServices,
        loadingServiceClose,
        loadingNextServicePage,
        newMessagesChatCounter,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseContext, MESSAGES_LIMIT };
