import { useRouter } from 'next/router';

import { useState } from 'react';

import toast from '@/helpers/toast';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import { useTranslate } from './useTranslate';

interface ModalCustomProps {
  title: string;
  text: string;
  idCustom?: string;
  onSuccess?: string;
  onError?: string;
  textAlert?: string;
  noRedirect?: boolean;
}

export default function useCustomModal<T>(
  mutation?: { [key: string]: MutationFunction },
  invalidateQueries?: string[],
) {
  const [id, setId] = useState<T>();
  const [keyItem, setKeyItem] = useState('default');
  const [objModal, setObjModal] = useState<T>();
  const { t } = useTranslate();

  const CustomModal = ({
    title,
    text,
    idCustom = 'default',
    onSuccess,
    onError,
    textAlert,
    noRedirect = false,
  }: ModalCustomProps) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { mutateAsync, isLoading } = useMutation(mutation![idCustom]);
    const router = useRouter();
    const queryClient = useQueryClient();

    const submit = async () => {
      try {
        await mutateAsync(id);
        await queryClient.invalidateQueries(invalidateQueries);
        setId(undefined);
        toast.success(onSuccess || t('actionSuccess'));
        if (!noRedirect) {
          router.replace('lista/1');
        }
      } catch (err) {
        setId(undefined);
        toast.error(onError || (err as Error).message || t('anErrorOccurred'));
      }
    };

    return (
      <>
        <Modal isOpen={!!id && keyItem === idCustom} onClose={() => setId(undefined)}>
          <ModalOverlay />
          <ModalContent className="card">
            <ModalHeader className="card-header">{title}</ModalHeader>
            {textAlert && (
              <ModalBody className="card-body">
                <Text color="red">{textAlert}</Text>
              </ModalBody>
            )}
            <ModalBody className="card-body">{text}</ModalBody>
            <ModalFooter className="card-footer">
              <Button colorScheme="red" mr={3} onClick={() => setId(undefined)} size="sm">
                {t('no')}
              </Button>
              <Button colorScheme="green" onClick={() => submit()} isLoading={isLoading} size="sm">
                {t('yes')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  interface ModalComponentProps extends ModalCustomProps {
    label?: string;
    isLoading?: boolean;
    isShow?: boolean;
    size?: string;
    noFooter?: boolean;
  }

  const ModalComponent: React.FC<Omit<ModalComponentProps, 'title' | 'text'>> = ({
    label,
    idCustom = 'default',
    isLoading,
    children,
    isShow = true,
    size = '4xl',
    noFooter = false,
  }) => {
    const submit = () => {
      if (isShow) {
        setId(undefined);
      }
    };

    return (
      <Modal size={size} isOpen={!!id && keyItem === idCustom} onClose={() => setId(undefined)}>
        <ModalOverlay />
        <ModalContent className="card">
          <ModalHeader className="card-header">
            {label}
            <ModalCloseButton top="16px" />
          </ModalHeader>
          <ModalBody className="card-body" p={0}>
            {children}
          </ModalBody>
          {!noFooter && (
            <ModalFooter className="card-footer">
              {!isShow && (
                <Button colorScheme="red" mr={3} onClick={() => setId(undefined)} size="sm">
                  {t('cancel')}
                </Button>
              )}
              <Button
                colorScheme={isShow ? 'gray' : 'green'}
                onClick={() => submit()}
                isLoading={isLoading}
                size="sm"
              >
                {isShow ? t('close') : t('confirm')}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    );
  };

  return {
    CustomModal,
    ModalComponent,
    onClose: () => setId(undefined),
    actionModal: (idx: T, keyAction = 'default', obj?: T) => {
      setId(idx);
      setKeyItem(keyAction);
      setObjModal(obj);
    },
    id,
    objModal,
  };
}
