export function currency(value: string | number | undefined) {
  if (!value) {
    return 'R$ 0,00';
  }
  try {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } catch {
    throw new Error('Cant parse strings, use currency()');
  }
}
