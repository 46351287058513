import { useQueryClient } from 'react-query';
import { useCallback, useContext } from 'react';

import { useCompany } from '@/hooks/useCompany';
import useCustomModal from '@/hooks/useCustomModal';
import ModalStoreClosed from '@/components/ModalStoreClosed';
import useStore from '@/hooks/useStore';
import { TotemCartContext } from '../contexts/TotemCartContext';
import { useTranslate } from '@/hooks/useTranslate';
import { playSound } from '../helpers/play-sound';
import { useChannel } from 'ably/react';
import { useAlertData } from '@/hooks/useAlertData';

type AblyEvents =
  | 'RECEIVED_ALERT'
  | 'RECEIVED_SALE'
  | 'UPDATE_COMPANY_STATUS'
  | 'TOTEM_CART_UPDATE';

export interface RealtimeCartModel {
  products: {
    uniqueKey: string;
    quantity: number;
    nameKds: string;
    name: {
      br: string;
    };
    additional: {
      id: number;
      name: {
        br: string;
      };
    }[];
    optionGroups: {
      options: {
        id: number;
        name: { br: string };
      }[];
    }[];
  }[];
  company: number;
  size: number;
  totem: {
    name: string;
    uuid: string;
  };
}

export const AblyEventsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();
  const { receiveUpdate } = useContext(TotemCartContext);
  const { setAlert } = useAlertData();

  const { isOpen: storeIsOpen, openStore, isCompletedApp } = useStore();
  const { ModalComponent, actionModal, onClose } = useCustomModal();
  const { t } = useTranslate();

  const queryClient = useQueryClient();

  const openModalNewSale = useCallback(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      if (isCompletedApp) {
        actionModal(true, 'newSale');
      }
    }, 3500);
    return () => {
      clearTimeout(timer);
    };
  }, [actionModal, isCompletedApp]);

  useChannel('kds-' + company, (message) => {
    // API JAVA recebe por string o json ai tem que usar JSON.parse, algo vindo de front e node já vem parseado.
    handleMessage(message.name as AblyEvents, message.data);
  });

  const handleMessage = useCallback(
    (name: AblyEvents, msgData: any) => {
      console.log('CHEGOU', name, msgData);
      switch (name) {
        case 'TOTEM_CART_UPDATE':
          receiveUpdate(msgData);
          return;
        case 'RECEIVED_SALE':
          queryClient.refetchQueries(['api.sale.list']);
          playSound();
          if (isCompletedApp && !storeIsOpen) {
            openModalNewSale();
          }
          return;
        case 'UPDATE_COMPANY_STATUS':
          queryClient.refetchQueries(['api.company.get']);
          return;
        case 'RECEIVED_ALERT':
          setAlert(JSON.parse(msgData));
          return;
      }
    },
    [isCompletedApp, openModalNewSale, queryClient, receiveUpdate, setAlert, storeIsOpen],
  );

  return (
    <>
      <ModalComponent label={t('newOrder')} idCustom="newSale" size="lg" noFooter>
        <ModalStoreClosed onClose={onClose} openStore={openStore} />
      </ModalComponent>
      {children}
    </>
  );
};
