const chars = 'AaBbC0cDdEe1FfGgH2hIiJj3KkLlM4mNnOo5PpQqR6rSsTt7UuVvW8wXxYy9Zz';

function boundedRandom(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const generateRandomUID = (length: number): string => {
  let uid = '';
  for (let i = 0; i < length; i++) {
    uid += chars.charAt(boundedRandom(0, chars.length - 1));
  }
  return uid;
};

export { generateRandomUID };
