import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';

import HeaderMenu from '@/components/HeaderMenu';
import useFirebase from '@/hooks/useFirebase';
import useStore from '@/hooks/useStore';
import useSocket from '../hooks/useSocket';
import { useTranslate } from '@/hooks/useTranslate';
import { useVariableValue } from '@devcycle/devcycle-react-sdk';
import useCashRegister from '../hooks/useCashRegister';
import toast from '@/helpers/toast';
import { useContext } from 'react';
import { IFoodContext } from '../contexts/iFoodContext';

export interface RoutesProps {
  id: number;
  name: string;
  routes: {
    id: number;
    label: string;
    link?: {
      href: string;
    };
    route?: string;
    show: boolean;
    onClick?: () => void;
  }[];
  data?: any;
  show: boolean;
}

const Header = () => {
  const { data } = useSocket();
  const { newMessagesChatCounter } = useFirebase();
  const { t } = useTranslate();
  const { isOpen, openModal, isCompletedApp } = useStore();
  const cashRegister = useCashRegister();
  const { enableIfood } = useContext(IFoodContext);
  const featureFlagCashRegister = useVariableValue('cash-register', false);

  const { isOpen: isOpenModalIfood, onClose, onOpen } = useDisclosure();

  const isNewDisabledScreen = useVariableValue('desativar-itens-kds-v-2', false);

  const routes: RoutesProps[] = [
    {
      id: 1,
      name: t('header.orders'),
      routes: [{ id: 1, label: t('header.orders'), route: '/pedidos', show: true }],
      data: data?.length || 0,
      show: true,
    },
    {
      id: 2,
      name: t('header.history'),
      routes: [{ id: 2, label: t('header.history'), route: '/historico-de-pedidos', show: true }],
      show: true,
    },
    {
      id: 3,
      name: t('header.storage'),
      routes: [
        {
          id: 3,
          label: t('header.storage.batches'),
          route: '/fornada',
          show: !useVariableValue('furnace-inventory', false),
        },
        {
          id: 33,
          label: t('header.storage.batches'),
          route: '/v2/fornada',
          show: useVariableValue('furnace-inventory', false),
        },
        {
          id: 8,
          label: 'Produtos',
          route: '/v2/produtos',
          show: isNewDisabledScreen,
        },
        {
          id: 50,
          label: 'Complementos',
          route: '/v2/opcionais',
          show: isNewDisabledScreen,
        },
        {
          id: 4,
          label: t('header.storage.products'),
          route: '/produtos',
          show: !isNewDisabledScreen,
        },
        {
          id: 5,
          label: t('header.storage.additionals'),
          route: '/adicionais',
          show: !isNewDisabledScreen,
        },
        {
          id: 6,
          label: t('header.storage.options'),
          route: '/opcionais',
          show: !isNewDisabledScreen,
        },
      ],
      show: true,
    },
    {
      id: 4,
      name: t('header.calls'),
      routes: [{ id: 6, label: t('header.calls'), route: '/chamados', show: true }],
      data: newMessagesChatCounter,
      show: useVariableValue('app-tickets', false),
    },
    {
      id: 5,
      name: t('header.cash'),
      routes: [{ id: 7, label: t('header.cash'), route: '/controle-de-caixa', show: true }],
      show: featureFlagCashRegister,
    },
    {
      id: 6,
      name: t('header.others'),
      routes: [
        { id: 8, label: t('header.extractionOrHelp.extraction'), route: '/fichas', show: true },
        { id: 12, label: t('header.manual'), route: '/receitas', show: true },

        { id: 9, label: t('header.extractionOrHelp.help'), route: '/ajuda', show: true },
        {
          id: 10,
          label: t('header.radio'),
          link: {
            href: 'http://radios.locutare.com.br:8050/index.html?sid=1',
          },
          show: true,
        },
        {
          id: 11,
          label: t('header.others.system'),
          link: {
            href: 'https://sistema.mais1cafe.com.br',
          },
          show: true,
        },
      ],
      show: true,
    },
    // {
    //   id: 12,
    //   name: t('video'),
    //   routes: [{ id: 12, label: t('video'), route: '/video', show: true }],
    //   show: true,
    // },
  ];

  return (
    <>
      <Modal isOpen={isOpenModalIfood} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Integração do iFood mudou!</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <Text fontSize="md">
              A integração do iFood mudou de lugar. Agora, contamos com o Widget direto do iFood,
              onde é possível acompanhar chats, status da loja, entre outros aspectos, diretamente
              do KDS. Para vincular o iFood da sua unidade ao KDS, siga o passo a passo em clicando
              no botão abaixo.
            </Text>

            <Text mt={3} fontSize="xs">
              Caso tenha qualquer problema não deixe de contactar nossa equipe de suporte!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Link
              target="_blank"
              isExternal
              textDecor={'none'}
              href="https://pro-franchising.atlassian.net/servicedesk/customer/portal/1/article/98009089"
            >
              <Button colorScheme={'green'}>Ver passo a passo</Button>
            </Link>
            <Button onClick={onClose} variant="ghost">
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex display="block" mt={1} mb={2} px={3}>
        <Flex mt={1} mb={2} gridGap={1}>
          {routes
            .filter((route) => route.show)
            .map((route) => (
              <HeaderMenu key={route.id} label={route.name} routes={route.routes} />
            ))}
          {isCompletedApp && (
            <Box>
              <Button
                colorScheme={isOpen ? 'red' : 'green'}
                display="block"
                h="50"
                maxH="50"
                onClick={() => {
                  if (featureFlagCashRegister && !isOpen && !cashRegister.isOpen) {
                    toast.error(t('cash.closed'));
                    return;
                  }
                  openModal();
                }}
                px={12}
                rounded="lg"
              >
                <Text>{isOpen ? t('header.closeStore') : t('header.openStore')}</Text>
                <Text fontWeight="normal" fontSize="sm" color="whiteAlpha.800">
                  {isOpen ? t('header.closeStore.description') : t('header.openStore.description')}
                </Text>
              </Button>
            </Box>
          )}
          {enableIfood && (
            <Box>
              <Button
                width="70px"
                h="50px"
                borderRadius={6}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p={2}
                onClick={onOpen}
                rounded="lg"
              >
                <Image width={60} height={60} src="/images/icons/iconIfood.svg" />
                <Text fontSize="12px" color="red.600">
                  {t('header.ifood.seeStatus')}
                </Text>
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
