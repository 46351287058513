import toast from './toast';

export const playSound = () => {
  try {
    new Audio('/audio/notify.mp3').play();
  } catch {
    toast.error(
      'Não foi possível tocar o som do KDS, interaja com a janela do navegador para resolver!',
    );
  }
};
