import { createContext, useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useCompany } from '../hooks/useCompany';
import { useTranslate } from '../hooks/useTranslate';

interface FirestoreCompany {
  withoutPaper: string[];
}

const PdvsContext = createContext({});

const PdvsProvider: React.FC = ({ children }) => {
  const [hasPaper, setHasPaper] = useState<string[]>([]);
  const { t } = useTranslate();
  const { company } = useCompany();
  const modal = useDisclosure();

  const listenPaperStatus = useCallback(() => {
    try {
      const serviceDoc = doc(getFirestore(), `/companies/${company}`);
      const listener = onSnapshot(serviceDoc, (snapshot) => {
        const companyData = snapshot.data() as FirestoreCompany;
        if (companyData) {
          setHasPaper(companyData.withoutPaper ?? []);
        }
      });
      return listener;
    } catch (error) {
      //
    }
  }, [company]);

  useEffect(() => {
    const listener = listenPaperStatus();

    return () => {
      if (listener) {
        listener();
      }
    };
  }, [listenPaperStatus]);
  //<Modal {...modal} isOpen={hasPaper.length > 0} isCentered>
  return (
    <PdvsContext.Provider value={{}}>
      <Modal {...modal} isOpen={false} isCentered>
        <ModalOverlay />
        <ModalContent
          width={500}
          height={130}
          bgColor="#00a868"
          padding="0px"
          flexDirection={'column'}
        >
          <ModalBody padding={'3px 3px'} flexDirection="row" flex={1} display={'flex'}>
            <Flex
              justifyContent="center"
              borderRadius={5}
              width={'65%'}
              height="100%"
              backgroundColor={'rgba(240, 229, 234, 0.49)'}
              flexDirection="column"
            >
              {hasPaper.map((totem) => (
                <Flex key={totem} flexDirection={'row'} alignItems="center">
                  <Img src="/images/icons/iconTotem.svg" height={10} />
                  <Text textTransform={'uppercase'} fontSize="sm" fontWeight="bold">
                    {totem}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <Flex
              width={'35%'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Text
                textTransform={'uppercase'}
                opacity={0.5}
                fontWeight="bold"
                lineHeight="1.2rem"
                fontSize="1.3rem"
                marginBottom={3}
                color="white"
              >
                {t('withoutPaper')}
              </Text>
              <Img src="/images/stone.svg" width={100} opacity={0.5} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {children}
    </PdvsContext.Provider>
  );
};

export { PdvsProvider, PdvsContext };
