import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { version } from '../../package.json';
const Footer = () => (
  <Box as="footer" p={4} fontWeight="light" color="#3b4153" mb={0}>
    <Flex justify="left" align="left" direction={{ base: 'column', md: 'row' }}>
      <Text>
        Powered by{' '}
        <Link href="https://profranchising.com.br/" target="_blank">
          PRO Franchising v{version}
        </Link>{' '}
      </Text>
    </Flex>
  </Box>
);

export default Footer;
