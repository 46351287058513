import { CircularProgress, Flex } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Flex position="fixed" top="50%" transform="translateY(-50%)">
      <CircularProgress isIndeterminate color="warning" />
    </Flex>
  );
};

export default Loading;
