import getConfig from 'next/config';

import { useQuery, useQueryClient } from 'react-query';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { io } from 'socket.io-client';

import api from '@/api';
import { useCompany } from '@/hooks/useCompany';
import { SaleData } from '@/api/controllers/sale.controller';
import useCustomModal from '@/hooks/useCustomModal';
import ModalStoreClosed from '@/components/ModalStoreClosed';
import useStore from '@/hooks/useStore';
import { TotemCartContext } from './TotemCartContext';
import { useTranslate } from '@/hooks/useTranslate';
import { STORAGE_ALERT_KEY } from '../providers/AlertProvider';
import { playSound } from '../helpers/play-sound';
import { useAlertData } from '@/hooks/useAlertData';

const { publicRuntimeConfig } = getConfig();

interface SocketContextRefresh {
  type?: 'sale' | 'refetch' | 'initial';
  appOrder?: number;
  refetchApp?: boolean;
  audioIsPlaying?: boolean;
  waitTime?: number;
}

type SocketType = 'polling' | 'websocket';
const socketOptions: SocketType[][] = [['websocket'], ['websocket', 'polling'], ['polling']];

export interface SocketCartModel {
  products: {
    uniqueKey: string;
    quantity: number;
    nameKds: string;
    name: {
      br: string;
    };
    additional: {
      id: number;
      name: {
        br: string;
      };
    }[];
    optionGroups: {
      options: {
        id: number;
        name: { br: string };
      }[];
    }[];
  }[];
  company: number;
  size: number;
  totem: {
    name: string;
    uuid: string;
  };
}

export interface AlertProps {
  type: 'MINIMUM_STOCK' | 'PROFRANCHISING' | 'FURNACE_RESET';
  message: string;
}

interface SocketContextProps {
  data: SaleData[] | undefined;
  refreshKDS: () => void;
  status: SocketStatus;
  socketType: SocketType[];
  setSocketType: Dispatch<SetStateAction<SocketType[]>>;
}

const withTimeout = (onSuccess: any, onTimeout: any, timeout: number) => {
  let called = false;

  const timer = setTimeout(() => {
    if (called) return;
    called = true;
    onTimeout();
  }, timeout);

  return (...args: any[]) => {
    if (called) return;
    called = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  };
};
const SocketContext = createContext({} as SocketContextProps);
// ERROR_1 = company null
type SocketStatus = 'CONNECTED' | 'DISCONNECTED' | 'ERROR' | 'CONNECTING' | 'ERROR_1';
const STORAGE_SOCKET_KEY = '@mais1kds/socket';
const DEFAULT_SOCKET_TYPE = socketOptions[1];

const SocketProvider: React.FC = ({ children }) => {
  const { company } = useCompany();
  const { receiveUpdate } = useContext(TotemCartContext);
  const [refresh, setRefresh] = useState<SocketContextRefresh>({
    type: 'initial',
    appOrder: undefined,
    refetchApp: false,
    audioIsPlaying: false,
  });
  const { alert, setAlert } = useAlertData();
  const [socketType, setSocketType] = useState<SocketType[]>(DEFAULT_SOCKET_TYPE);
  const [loadingSocket, setLoadingSocket] = useState(true);

  const [status, setStatus] = useState<SocketStatus>('CONNECTING');

  const [data, setData] = useState<SaleData[]>();

  const { isOpen: storeIsOpen, openStore, isCompletedApp } = useStore();
  const { ModalComponent, actionModal, onClose } = useCustomModal();
  const { t } = useTranslate();

  const alertStorage = localStorage.getItem(STORAGE_ALERT_KEY);

  useQuery<SaleData[]>(['api.sale.list', refresh], api.sale.list, {
    refetchInterval: refresh?.type === 'sale' ? 3000 : undefined,
    onSuccess: (result) => {
      if (
        refresh.refetchApp &&
        (!data?.find((item) => item?.id === result[result?.length - 1]?.id) ||
          result[result?.length - 1]?.id === refresh.appOrder) &&
        !!data?.length
      ) {
        playSound();
        setRefresh(() => ({
          type: 'sale',
          appOrder: result[result?.length - 1]?.id,
          audioIsPlaying:
            !!result[result?.length - 1]?.id &&
            (result[result?.length - 1]?.saleMode === 'APP' ||
              result[result?.length - 1]?.saleMode === 'IFOOD') &&
            result[result?.length - 1]?.currentDate >= result[result?.length - 1]?.visibleDate,
          refetchApp: false,
        }));
      }

      if (refresh?.audioIsPlaying) {
        const filter = result?.find((item) => item?.id === refresh?.appOrder);
        if (
          !filter ||
          ((filter?.saleMode === 'APP' || filter?.saleMode === 'IFOOD') &&
            filter.status !== 'PENDING')
        ) {
          setRefresh((prev) => ({
            ...prev,
            appOrder: undefined,
            audioIsPlaying: false,
          }));
        }
      }

      if (isCompletedApp && !storeIsOpen && refresh.type === 'sale') {
        openModalNewSale();
      }

      if (!refresh?.refetchApp) {
        setRefresh((prev) => ({ ...prev, type: 'initial' }));
      }

      setData(result);
      queryClient.setQueryData('api.sale.list', result);
    },
  });

  const refreshKDS = async () => {
    setRefresh((prev) => ({ ...prev, type: 'refetch' }));
  };

  const openModalNewSale = () => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      if (isCompletedApp) {
        actionModal(true, 'newSale');
      }
    }, 3500);
    return () => {
      clearTimeout(timer);
    };
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!company) {
      setStatus('ERROR_1');
      return;
    }

    const connection = io(publicRuntimeConfig.WS_URL, {
      reconnection: true,
      reconnectionDelayMax: 1000,
      extraHeaders: { 'mais1-client': `kds-${company}` },
      transports: socketType,
    });

    connection.on('connect', () => {
      connection.emit(
        'join-kds',
        company,
        withTimeout(
          () => {
            setStatus('CONNECTED');
          },
          () => {
            console.log('Ocorreu um problema: O servidor de socket não respondeu!', 'Ack failed!');
            setStatus('ERROR');
          },
          3000,
        ),
      );
    });
    connection.on('disconnect', () => {
      setStatus('DISCONNECTED');
    });
    connection.on('reconnect', () => {
      connection.emit(
        'join-kds',
        company,
        withTimeout(
          () => {
            setStatus('CONNECTED');
          },
          () => {
            console.log('Ocorreu um problema: O servidor de socket não respondeu!', 'Ack failed!');
            setStatus('ERROR');
          },
          3000,
        ),
      );
    });
    connection.on('connect_error', () => {
      setStatus('ERROR');
    });

    connection.on(`REFRESH_KDS`, (...args) => {
      if (args.length !== 1) return;
      const { sound, sale } = args[0].data;

      if (sound) {
        setRefresh((prev) => ({
          ...prev,
          appOrder: sale,
        }));
      }

      setTimeout(() => setRefresh((prev) => ({ ...prev, type: 'sale', refetchApp: sound })), 3000);
    });

    connection.on(`KDS_COMPANY_UPDATE`, () => {
      queryClient.refetchQueries(['api.company.get']);
    });

    connection.on('CART_REFRESH', (...args) => {
      const cart = args[0] as SocketCartModel;
      receiveUpdate(cart);
    });

    connection.on('SPAM_KDS', (...args) => {
      if (!alert?.type) {
        localStorage.setItem(STORAGE_ALERT_KEY, JSON.stringify(args[0]));
      }
      return setAlert(JSON.parse(alertStorage as string));
    });

    return () => {
      connection.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, socketType]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const audioPlay = () => {
      timer = setTimeout(() => {
        if (refresh?.audioIsPlaying) {
          playSound();
          audioPlay();
        }
      }, 5000);
    };
    if (refresh?.audioIsPlaying) {
      const filter = data?.find((item) => item?.id === refresh?.appOrder);
      if (
        !filter ||
        ((filter?.saleMode === 'APP' || filter?.saleMode === 'IFOOD') &&
          filter.status !== 'PENDING')
      ) {
        setRefresh((prev) => ({
          ...prev,
          appOrder: undefined,
          audioIsPlaying: false,
        }));
      }
      audioPlay();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const loadSocketType = useCallback(async () => {
    const socket = await localStorage.getItem(STORAGE_SOCKET_KEY);
    if (socket) {
      setSocketType(JSON.parse(socket));
    } else {
      setSocketType(DEFAULT_SOCKET_TYPE);
    }
    setLoadingSocket(false);
  }, [setSocketType]);

  useEffect(() => {
    loadSocketType();
  }, [loadSocketType]);

  useEffect(() => {
    if (socketType && !loadingSocket) {
      localStorage.setItem(STORAGE_SOCKET_KEY, JSON.stringify(socketType));
    }
  }, [socketType, loadingSocket]);

  return (
    <SocketContext.Provider value={{ data, status, socketType, refreshKDS, setSocketType }}>
      <ModalComponent label={t('newOrder')} idCustom="newSale" size="lg" noFooter>
        <ModalStoreClosed onClose={onClose} openStore={openStore} />
      </ModalComponent>
      {children}
    </SocketContext.Provider>
  );
};

export type { SocketType };
export { SocketProvider, SocketContext, socketOptions };
