import { useAlertData } from '@/hooks/useAlertData';
import { useTranslate } from '@/hooks/useTranslate';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const STORAGE_ALERT_KEY = '@mais1kds/alert';

const AlertProvider: React.FC = ({ children }) => {
  const { alert, setAlert } = useAlertData();
  const router = useRouter();
  const [timer, setTimer] = useState<number>(7);
  const [disabled, setDisabled] = useState<boolean>(true);
  const modal = useDisclosure();
  const { t } = useTranslate();

  useEffect(() => {
    if (alert?.type === 'PROFRANCHISING') {
      setTimeout(() => {
        if (timer === 0) {
          setTimer(disabled ? 3 : 7);
          setDisabled(!disabled);
          return;
        } else {
          setTimer((prev: number) => prev - 1);
          return;
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, alert?.type === 'PROFRANCHISING']);

  return (
    <>
      <Modal
        {...modal}
        isOpen={!!alert?.type && router.pathname.includes('/pedidos')}
        size={'3xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1.5rem">
            <Text textAlign="center">
              {alert?.type === 'MINIMUM_STOCK' ? t('alert.title.furnace') : t('alert.title.pro')}
            </Text>
          </ModalHeader>
          <ModalBody p={6} textAlign="center">
            <Text fontSize="1.2rem">{alert?.message}</Text>
            {alert?.type === 'MINIMUM_STOCK' && (
              <Text fontSize="1.2rem" mt={4}>
                {t('alert.title.prepare')}
              </Text>
            )}
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            {alert?.type === 'PROFRANCHISING' && (
              <Text color="blackAlpha.600" fontSize="sm">
                PRO Franchising
              </Text>
            )}
            {alert?.type === 'PROFRANCHISING' ? (
              <Button
                onClick={() => {
                  setAlert({} as any);
                  localStorage.removeItem(STORAGE_ALERT_KEY);
                }}
                isDisabled={disabled}
                colorScheme="green"
              >
                {disabled ? t('alert.wait') : t('alert.title.confirm')} {timer}s
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setAlert({} as any);
                  localStorage.removeItem(STORAGE_ALERT_KEY);
                }}
                _focus={{}}
                colorScheme="green"
                ml="auto"
              >
                {t('alert.title.confirm')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </>
  );
};

export { AlertProvider, STORAGE_ALERT_KEY };
