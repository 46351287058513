import { createContext } from 'react';

import { useQuery } from 'react-query';

import api from '../api';
import { StatusPageModel } from '../api/controllers/status-page/model/status-page.model';

interface StatusPageContextProps {
  data: StatusPageModel | undefined;
}

const StatusPageContext = createContext({} as StatusPageContextProps);

const StatusPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data } = useQuery('api.statusPage.find', api.statusPage.find, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return <StatusPageContext.Provider value={{ data }}>{children}</StatusPageContext.Provider>;
};

export { StatusPageContext, StatusPageProvider };
